export function overrideIsSelected() {
  return {
    mounted() {
      console.log(this.$options.components.Treeselect);

      const TreeselectComponent = this.$options.components.Treeselect;
      if (TreeselectComponent) {
        const originalMounted = TreeselectComponent.mounted;
        TreeselectComponent.mounted = async function () {
        const self = this;
          console.log("mounted");
        try {
        /*   if (originalMounted) {
            await new Promise((resolve, reject) => {
              try {
                originalMounted.call(this);
                resolve();
              } catch (error) {
                reject(error);
              }
            });
          }*/
          console.log("tree mounted");

          this.updateHiddenInputField = function () {
            const formElement = self.$el.closest('form');
            if (formElement) {
              if (self.name !== undefined) {
                const existingInputs = formElement.querySelectorAll(`input[name^="${self.name.replace('[]', '')}"]`);
                existingInputs.forEach(input => {
                  if (input.parentNode === formElement) {
                    formElement.removeChild(input);
                  }
                });
              }
              if (Array.isArray(self.modelValue)) {
                self.modelValue.forEach((value) => {
                  const hiddenInput = document.createElement('input');
                  hiddenInput.type = 'hidden';
                  hiddenInput.name = self.name;
                  hiddenInput.value = value;
                  formElement.appendChild(hiddenInput);
                });
              } else if (!self.disabled) {
                const hiddenInput = document.createElement('input');
                hiddenInput.type = 'hidden';
                hiddenInput.name = self.name;
                hiddenInput.value = self.modelValue;
                formElement.appendChild(hiddenInput);
              }
            }
          };

          this.$watch('modelValue', () => {
            console.log("name- ", self.name, " value- ", self.modelValue);
            this.updateHiddenInputField();
          }, { deep: true, immediate: true });

          this.updateHiddenInputField();

        } catch (error) {
          console.error("Error during tree mount:", error);
        }
      };
    } else {
      console.error("Treeselect component not found");
    }

    },
  };
}
